@tailwind base;
@tailwind components;
@tailwind utilities;

strong {
  font-weight: 900;
}

.animation {
  background: linear-gradient(to right, #fff 50%, #000 50%);
  background-size: 200% 200%;
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.animation-bg:hover .animation {
  animation: animated_text 400ms ease-in-out;
  animation-fill-mode: forwards;
}

@keyframes animated_text {
  0% {
    background-position: 0% 0%;
  }
  100% {
    background-position: -100% -100%;
  }
}

.animation-bg {
  background: linear-gradient(to left, #fff 50%, transparent 50%);
  background-size: 200% 200%;
  opacity: 1 !important;
}

.animation-bg:hover {
  animation: animated_bg 400ms ease-in-out;
  animation-fill-mode: forwards;
}

@keyframes animated_bg {
  0% {
    background-position: 0% 0%;
  }
  100% {
    background-position: -100% -100%;
  }
}
* {
  scroll-behavior: smooth !important;
}

.animate {
  animation: animated_width 10s ease-in-out;
  width: 5%;
  height: 100%;
  position: absolute;
  animation-fill-mode: forwards;
}
@keyframes animated_width {
  0% {
    width: 10%;
  }
  10% {
    width: 20%;
  }
  30% {
    width: 30%;
  }
  50% {
    width: 60%;
  }
  80% {
    width: 70%;
  }
  100% {
    width: 100%;
  }
}

.animate-header {
  top: -70px;
  transition: all 0.3s ease-in-out;
}

.animate-header-visible {
  top: 0;
  transition: all 0.3s ease-in-out;
}
